import { cn } from '@/lib/utils';
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Card } from './ui/card';
import { FadeIn } from './ui/fade-in';

const featuredTestimonial = {
  body: 'Ganesh Mani has been an Easy to adapt guy in many situations. Knowing him from his University days, the curiosity that he has to learn about a new technology/skill and the level of dedication that he puts to bring things to life has been immense. At times when my team had issues with going live for a few projects, the guidance and mentoring that Ganesh gave us made us to stick with the timeline',
  author: {
    name: 'Dinesh Sridhar',
    linkedinUrl: 'https://www.linkedin.com/in/dineshsridhar/',
    position: 'Founder & CEO, Calotropis & TechWift',
    imageUrl: '/testimonials/dinesh_sridhar.png',
    logoUrl:
      'https://www.linkedin.com/in/dineshsridhar?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_recommendations_details%3BsBrKeXXNTXmy6EsxARrl8w%3D%3D',
  },
};

const testimonialsData = [
  [
    [
      {
        body: 'Ganesh is a geek in the making. Alert intuitive go getter. Ambitious, enthusiastic and eager to learn.',
        author: {
          name: 'Raju Kandasamy',
          socialUrl: 'https://www.linkedin.com/in/rajukandasamy/',
          position: 'Principal Consultant | Thoughtworks',
          imageUrl: '/testimonials/raju_kandasamy.png',
        },
      },
      // {
      //   body: 'Ganesh Mani is an very well educated, very well organised, fast-learning and extremely thorough Software Engineer with special skills and interests in the various technologies and tools. I was wondering with the way that he handle the project from requirement analyzation level to delivery level at the earlier stage of his career.',
      //   author: {
      //     name: 'Sivasankar Sekar',
      //     socialUrl: 'https://www.linkedin.com/in/siva01sankar/',
      //     position: 'Senior Technical Leader',
      //     imageUrl: '/testimonials/sivasankar.png',
      //   },
      // },
    ],

    [
      {
        body: 'Ganesh Mani is an very well educated, very well organised, fast-learning and extremely thorough Software Engineer with special skills and interests in the various technologies and tools. I was wondering with the way that he handle the project from requirement analyzation level to delivery level at the earlier stage of his career.',
        author: {
          name: 'Sivasankar Sekar',
          socialUrl: 'https://www.linkedin.com/in/siva01sankar/',
          position: 'Senior Technical Leader',
          imageUrl: '/testimonials/sivasankar.png',
        },
      },
    ],
    // [
    //   {
    //     body: 'Thank you very much for creating this article. I am a beginner with Next.js, and I found your piece extremely helpful. It also led me to explore the rest of your impressive blog 🤩',
    //     author: {
    //       name: 'Pierre Rognion',
    //       socialUrl:
    //         'https://www.linkedin.com/feed/update/urn:li:activity:7117758220042915840?commentUrn=urn%3Ali%3Acomment%3A%28activity%3A7117758220042915840%2C7121397129381568512%29&dashCommentUrn=urn%3Ali%3Afsd_comment%3A%287121397129381568512%2Curn%3Ali%3Aactivity%3A7117758220042915840%29',
    //       position: 'AI Manager @Twelve Consulting',
    //       imageUrl: '/testimonials/pierre-rognion.jpeg',
    //     },
    //   },
    // ],
    // [
    //   {
    //     body: "Many thanks for your detailed and informed advice on choosing a tech stack for our health startup. The level of detail in your assessment was unexpected and highly appreciated. Your checklist, with its clear and useful tips, has been invaluable—it pointed out things we hadn't thought of by ourselves.",
    //     author: {
    //       name: 'Daniel Biene',
    //       socialUrl: 'https://www.linkedin.com/in/danielbiene/',
    //       position: 'Digital Entrepreneur',
    //       imageUrl: '/testimonials/daniel_biene.jpeg',
    //     },
    //   },

    //   {
    //     body: 'As a recent bootcamp grad who is often overwhelmed by how much is out there and how the bar seems so much higher for a chance at employment, stuff like this is super helpful. Really appreciate it! It was nice to see some of my current frameworks included',
    //     author: {
    //       name: 'Due_Advisor925',
    //       socialUrl:
    //         'https://www.reddit.com/r/nextjs/comments/15fplwb/comment/jug0f4z/?utm_source=share&utm_medium=web2x&context=3',
    //       position: '@u/Due_Advisor925',
    //       imageUrl: '/testimonials/due_advisor_925.png',
    //     },
    //   },
    //   {
    //     body: '@ganeshmani thank you for the @lmsqueezy setup walk-through blog post you have! I was having a hard time figuring out how to test my webhooks locally and you saved me a bunch of time with your article!',
    //     author: {
    //       name: 'Dorian Develops',
    //       socialUrl: 'httpshttps://twitter.com/DorianDevelops',
    //       position: '@DorianDevelops',
    //       imageUrl: '/testimonials/dorian_develops.jpeg',
    //     },
    //   },
    // ],
  ],
];

const Testimonials = ({ children }: { children: React.ReactNode }) => {
  return (
    <FadeIn className="relative isolate pb-16 pt-12 sm:pt-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        {children}
        <div className="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4">
          <Card className="rounded-2xl shadow-lg sm:col-span-2 xl:col-start-2 xl:row-end-1">
            <blockquote className="p-6 text-lg font-semibold leading-7 tracking-tight sm:p-12 sm:text-xl sm:leading-8">
              <p>{`“${featuredTestimonial.body}”`}</p>
            </blockquote>
            <figcaption className="flex flex-wrap items-center gap-x-4 gap-y-4 border-t px-6 py-4 sm:flex-nowrap">
              <Avatar>
                <AvatarImage
                  src={featuredTestimonial.author.imageUrl}
                  alt={featuredTestimonial.author.name}
                />
                <AvatarFallback>
                  {featuredTestimonial.author.name.substring(0, 2)}
                </AvatarFallback>
              </Avatar>

              <a
                target="_blank"
                className="flex-auto"
                href={featuredTestimonial.author.linkedinUrl}
              >
                <div className="flex-auto">
                  <div className="font-semibold">
                    {featuredTestimonial.author.name}
                  </div>
                  <div className="text-muted-foreground">{`${featuredTestimonial.author.position}`}</div>
                </div>
              </a>

              <img
                className="h-10 w-auto flex-none"
                src={featuredTestimonial.author.logoUrl}
                alt=""
              />
            </figcaption>
          </Card>
          {testimonialsData.map((columnGroup, columnGroupIdx) => (
            <div
              key={columnGroupIdx}
              className="space-y-8 xl:contents xl:space-y-0 sm:col-span-2"
            >
              {columnGroup.map((column, columnIdx) => (
                <div
                  key={columnIdx}
                  className={cn(
                    (columnGroupIdx === 0 && columnIdx === 0) ||
                      (columnGroupIdx === testimonialsData.length - 1 &&
                        columnIdx === columnGroup.length - 1)
                      ? 'xl:row-span-2'
                      : 'xl:row-start-1',
                    'space-y-8',
                  )}
                >
                  {column.map((testimonial) => (
                    <Card
                      key={testimonial.author.socialUrl}
                      className="rounded-2xl p-6 shadow-lg"
                    >
                      <blockquote className="">
                        <p>{`“${testimonial.body}”`}</p>
                      </blockquote>
                      <figcaption className="mt-6 flex items-center gap-x-4">
                        <Avatar>
                          <AvatarImage
                            src={testimonial.author?.imageUrl}
                            alt={testimonial.author.name}
                          />
                          <AvatarFallback>
                            {testimonial.author.name.substring(0, 2)}
                          </AvatarFallback>
                        </Avatar>
                        <a href={testimonial.author.socialUrl}>
                          <div className="font-semibold">
                            {testimonial.author.name}
                          </div>
                          <div className="text-muted-foreground">{`${testimonial.author.position}`}</div>
                        </a>
                      </figcaption>
                    </Card>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </FadeIn>
  );
};

export default Testimonials;
